import classNames from "classnames";
import { Entry } from "contentful";
import { AnimatePresence, motion } from "framer-motion";
import Image from "next/image";
import Link from "next/link";
import { useRouter } from "next/router";
import { AbstractIntlMessages, useTranslations } from "next-intl";
import { Dispatch, Fragment, SetStateAction, useEffect, useState } from "react";
import { useRecoilState, useSetRecoilState } from "recoil";

import { countryDialogOpenedState } from "~/atoms/countryDialog";
import { mobileMenuOpenedState } from "~/atoms/mobileMenu";
import { useBreakpoint } from "~/contexts/breakpoint";
import EvaSearchDialog from "~/pages/search/eva-search-dialog";
import Editorials from "~/types/editorials";
import contentfulUtils from "~/utils/contentful-utils";
import crossCountryUtils from "~/utils/crossCountry-utils";
import { assertEditorialType, isActiveEntry } from "~/utils/editorial-utils";
import textUtils from "~/utils/text-utils";

import Dialog from "../common/dialog";
import Icon from "../common/icon";
import AlgoliaCustomAutocomplete from "../search/algolia-custom-autocomplete";
import dialogStyles from "../search/search-dialog.module.scss";
import styles from "./menu-mobile.module.scss";
import MenuMobileItem from "./menu-mobile-item";
import NavigationItemCollectionsVariantItem from "./navigation-item-collections-variant/navigation-item-collections-variant-item";
import NavigationServices from "./navigation-services";

const variants = {
  enter: (direction: number) => {
    return {
      x: direction > 0 ? "25%" : "-25%",
      opacity: 0,
    };
  },
  center: {
    zIndex: 1,
    x: 0,
    opacity: 1,
  },
  exit: (direction: number) => {
    return {
      zIndex: 0,
      x: direction > 0 ? "-25%" : "25%",
      opacity: 0,
    };
  },
};

type NavState = {
  navLevels: Entry<
    | Editorials.NavigationItemStandard
    | Editorials.NavigationItemCollectionsVariant
    | Editorials.NavigationItemServicesVariant
    | Editorials.MenuGroup
  >[];
  direction: number;
};

type NavigationLevelProps = {
  entry: Entry<
    | Editorials.NavigationItemStandard
    | Editorials.NavigationItemCollectionsVariant
    | Editorials.NavigationItemServicesVariant
    | Editorials.MenuGroup
  >[];
  setNavState: Dispatch<SetStateAction<NavState>>;
};

export function NavigationLevel(props: NavigationLevelProps) {
  switch (props.entry[0].sys.contentType.sys.id) {
    case "menuGroup":
      assertEditorialType<Editorials.MenuGroup>(props.entry[0], "menuGroup");
      return (
        <div className={styles.box}>
          {props.entry[0].fields.items?.filter(isActiveEntry).map((el, i) => (
            <MenuMobileItem
              key={i}
              item={el}
              setCurrent={(updated) => props.setNavState({ navLevels: [updated, ...props.entry], direction: 1 })}
              currentLevel={props.entry.length}
            />
          ))}
        </div>
      );
    case "navigationItemStandard":
      assertEditorialType<Editorials.NavigationItemStandard>(props.entry[0], "navigationItemStandard");
      let mainMenu: Entry<Editorials.MenuGroup>[] | undefined = undefined;

      if (!isActiveEntry(props.entry[0].fields.menu!)) {
        mainMenu = undefined;
      } else {
        mainMenu = props.entry[0].fields.menu?.fields.main?.filter(isActiveEntry).flatMap((el) => {
          if (el.sys.contentType.sys.id == "menuColumn") {
            assertEditorialType<Editorials.MenuColumn>(el, "menuColumn");
            return [...el.fields.items?.filter(isActiveEntry)];
          } else {
            assertEditorialType<Editorials.MenuGroup>(el, "menuGroup");
            return el;
          }
        });
      }

      return (
        <>
          {isActiveEntry(props.entry[0].fields.menu!) ? (
            <div className={classNames(styles.box, styles.secondLevel)}>
              <div className={styles.mainMenu}>
                {mainMenu?.map((el, i) => (
                  <MenuMobileItem
                    key={i}
                    item={el}
                    setCurrent={(updated) => props.setNavState({ navLevels: [updated, ...props.entry], direction: 1 })}
                  />
                ))}
              </div>
              {props.entry[0].fields.menu?.fields.highlight && (
                <div className={styles.highlightMenu}>
                  {props.entry[0].fields.menu.fields.highlight.map((el, i) => (
                    <MenuMobileItem
                      key={i}
                      item={el}
                      setCurrent={(updated) =>
                        props.setNavState({ navLevels: [updated, ...props.entry], direction: 1 })
                      }
                      className={styles.highlightItem}
                    />
                  ))}
                </div>
              )}
            </div>
          ) : null}
          {props.entry[0].fields.collections && isActiveEntry(props.entry[0].fields.collections) ? (
            <div className={classNames(styles.box, styles.collectionsBox)}>
              <MenuMobileItem
                item={props.entry[0].fields.collections}
                setCurrent={(updated) => props.setNavState({ navLevels: [updated, ...props.entry], direction: 1 })}
              />
            </div>
          ) : null}
          {props.entry[0].fields.services && isActiveEntry(props.entry[0].fields.services) ? (
            <NavigationServices services={props.entry[0].fields.services} />
          ) : null}
        </>
      );
    case "navigationItemCollectionsVariant":
      assertEditorialType<Editorials.NavigationItemCollectionsVariant>(
        props.entry[0],
        "navigationItemCollectionsVariant"
      );
      return (
        <div className={classNames(styles.box, styles.collectionsWrapper)}>
          {props.entry[0].fields.list?.filter(isActiveEntry).map((el, i) => (
            <MenuMobileItem
              key={i}
              item={el}
              setCurrent={(updated) => props.setNavState({ navLevels: [updated, ...props.entry], direction: 1 })}
            />
          ))}
        </div>
      );
    case "navigationItemServicesVariant":
      assertEditorialType<Editorials.NavigationItemServicesVariant>(props.entry[0], "navigationItemServicesVariant");
      return (
        <div className={classNames(styles.servicesBox, styles.servicesWrapper)}>
          {props.entry[0].fields.list?.filter(isActiveEntry).map((el, i) => (
            <MenuMobileItem
              key={i}
              item={el}
              setCurrent={(updated) => props.setNavState({ navLevels: [updated, ...props.entry], direction: 1 })}
            />
          ))}
          {props.entry[0].fields.collections && isActiveEntry(props.entry[0].fields.collections) ? (
            <div className={classNames(styles.box, styles.collectionsBox)}>
              <MenuMobileItem
                item={props.entry[0].fields.collections}
                setCurrent={(updated) => props.setNavState({ navLevels: [updated, ...props.entry], direction: 1 })}
              />
            </div>
          ) : null}
        </div>
      );

    case "navigationItemStandardCollections":
      assertEditorialType<Editorials.NavigationItemStandardCollections>(
        props.entry[0],
        "navigationItemStandardCollections"
      );
      return (
        <div className={classNames(styles.box, styles.collectionsWrapper)}>
          {props.entry[0].fields.list?.filter(isActiveEntry).map((el, i) => (
            <NavigationItemCollectionsVariantItem key={i} entry={el} />
          ))}
        </div>
      );

    default: {
      return null;
    }
  }
}

type Props = {
  nav: Entry<Editorials.Nav>;
  messages?: AbstractIntlMessages;
  searchSuggestions?: string[];
};

export default function MenuMobile(props: Props) {
  const t = useTranslations();

  const menuMobileLinkList = props.nav.fields.menuMobileLinkList?.filter(isActiveEntry);
  const [menuOpen, setMenuOpen] = useRecoilState(mobileMenuOpenedState);
  const [{ navLevels, direction }, setNavState] = useState<NavState>({ navLevels: [], direction: 0 });

  const router = useRouter();
  const breakpoint = useBreakpoint();

  const country = crossCountryUtils.getCurrentCountryCode(router);
  const setCountryDialogOpenedState = useSetRecoilState(countryDialogOpenedState);

  const [openSearch, setOpenSearch] = useState(false);
  // EVA search
  const [searchQuery, setSearchQuery] = useState("");

  // EVA search
  useEffect(() => {
    if (searchQuery != "") {
      setOpenSearch(true);
    }
  }, [searchQuery]);

  // close menu/search on change route
  useEffect(() => {
    closeDialog();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router.asPath]);

  function openCountryDialog() {
    setCountryDialogOpenedState(true);
  }

  function closeDialog() {
    setCountryDialogOpenedState(false);
    setMenuOpen(false);
    setTimeout(() => {
      setOpenSearch(false);
    }, 500);
  }
  const inspectorModeBackLink = contentfulUtils.useInspectorMode(navLevels?.[0]?.fields?.link);

  return (
    <>
      {breakpoint == "mobile" && (
        <button className={styles.button} onClick={() => setMenuOpen(true)}>
          <Icon name="menu" />
        </button>
      )}
      <button
        className={classNames(styles.button, styles.searchButton)}
        onClick={() => {
          setMenuOpen(true);
          setOpenSearch(true);
        }}
      >
        <Icon name="search" />
        {breakpoint == "desktop" && <span>{t("generic.search_placeholder")}</span>}
      </button>
      <Dialog
        isOpen={menuOpen}
        title={
          process.env.NEXT_PUBLIC_DISABLE_ALGOLIA === "true" ? (
            <label>
              <Icon name="search" />
              <input
                type="search"
                id="site-search"
                placeholder={t("generic.search_placeholder")}
                className={styles.searchInput}
                value={searchQuery}
                onChange={(ev) => setSearchQuery(ev.target.value)}
                autoFocus={openSearch}
                onKeyDown={(ev) => {
                  if (ev.key == "Enter") {
                    router.push(`/search?query=${encodeURIComponent(searchQuery)}`);
                  }
                }}
              />
            </label>
          ) : (
            <AlgoliaCustomAutocomplete
              panelContainer={"#autocomplete-panel"}
              panelPlacement={"full-width"}
              placeholder={t("generic.search_placeholder")}
              detachedMediaQuery="none"
              openOnFocus
              className={styles["ais-SearchBox"]}
              classNames={{
                root: styles["ais-SearchBox"],
                form: styles["ais-SearchBox-form"],
                panel: dialogStyles.searchWrapper,
                submitButton: styles["aa-SubmitButton"],
                clearButton: styles["aa-ClearButton"],
                list: dialogStyles.searchList,
                item: styles["aa-Item"],
                source: styles["aa-Source"],
                sourceFooter: styles["aa-SourceFooter"],
                input: styles["ais-SearchBox-input"],
              }}
              messages={props.messages}
              isOpen={openSearch}
              setOpen={() => setOpenSearch(true)}
              onClose={closeDialog}
            />
          )
        }
        onClose={closeDialog}
        customStyles={{
          overlay: styles.overlay,
          container: styles.dialogContainer,
          wrapper: styles.dialogWrapper,
          panel: styles.dialogPanel,
          header: styles.dialogHeader,
          top: styles.dialogTop,
          content: styles.dialogContent,
          title: styles.dialogTitle,
        }}
      >
        <div id="autocomplete-panel" />
        <AnimatePresence initial={false} custom={direction}>
          {openSearch ? (
            process.env.NEXT_PUBLIC_DISABLE_ALGOLIA === "true" ? (
              <EvaSearchDialog
                query={searchQuery}
                setQuery={setSearchQuery}
                searchSuggestions={props.searchSuggestions}
              />
            ) : (
              <></>
            )
          ) : (
            <motion.nav
              key={navLevels[0]?.sys.id || "base"}
              custom={direction}
              variants={variants}
              initial="enter"
              animate="center"
              exit="exit"
              className={styles.nav}
            >
              {navLevels.length > 0 ? (
                <>
                  <div className={classNames(styles.box, styles.heading)} {...inspectorModeBackLink?.getProps("text")}>
                    <button
                      aria-label={`${
                        isActiveEntry(navLevels[0].fields.link) ? navLevels[0].fields.link.fields.text : ""
                      } ${t("generic.back")}`}
                      onClick={() =>
                        setNavState({
                          navLevels: navLevels.filter((a) => a.sys.id !== navLevels[0].sys.id),
                          direction: -1,
                        })
                      }
                    >
                      <Icon name="chevron-left" />
                      {isActiveEntry(navLevels[0].fields.link) ? (
                        <span>{navLevels[0].fields.link.fields.text}</span>
                      ) : null}
                    </button>
                    {isActiveEntry(navLevels[0].fields.link) ? (
                      <Link
                        href={textUtils.sanitizeContentfulUrl(navLevels[0].fields.link, router)}
                        target={navLevels[0].fields.link.fields.openOnANewTab ? "_blank" : undefined}
                        className={styles.link}
                      >
                        {t("generic.view_all")}
                      </Link>
                    ) : null}
                  </div>
                  <NavigationLevel entry={navLevels} setNavState={setNavState} />
                </>
              ) : (
                <>
                  <div className={styles.box}>
                    {props.nav.fields.navigationItems
                      ?.filter(isActiveEntry)
                      .filter((el) => el.fields.isHighlighted !== true)
                      .map((el, i) => (
                        <MenuMobileItem
                          key={i}
                          item={el}
                          setCurrent={(updated) => setNavState({ navLevels: [updated, ...navLevels], direction: 1 })}
                        />
                      ))}
                  </div>
                  <div className={classNames(styles.box, styles.isHighlighted)}>
                    {props.nav.fields.navigationItems
                      ?.filter(isActiveEntry)
                      .filter((el) => el.fields.isHighlighted)
                      .map((el, i) => (
                        <MenuMobileItem
                          key={i}
                          item={el}
                          setCurrent={(updated) => setNavState({ navLevels: [updated, ...navLevels], direction: 1 })}
                        />
                      ))}
                  </div>
                  {menuMobileLinkList && menuMobileLinkList.length > 0 ? (
                    <>
                      <div className={styles.boxAccount}>
                        {menuMobileLinkList
                          .filter((_, idx) => idx % 2 === 0)
                          .map((menuMobileLink) => {
                            const { text, openOnANewTab, icon } = menuMobileLink.fields;
                            const inspectorModeLink = contentfulUtils.useInspectorMode(menuMobileLink);

                            return (
                              <Link
                                key={menuMobileLink.sys.id}
                                prefetch={false}
                                href={textUtils.sanitizeContentfulUrl(menuMobileLink, router)}
                                target={openOnANewTab ? "_blank" : undefined}
                              >
                                {icon && isActiveEntry(icon) ? (
                                  <Icon
                                    width={20}
                                    height={20}
                                    svgMedia={icon.fields.svgMedia}
                                    name={icon.fields.svgIconId}
                                  />
                                ) : null}
                                <span {...inspectorModeLink?.getProps("text")}>{text}</span>
                              </Link>
                            );
                          })}
                      </div>
                      <div className={styles.boxAccount}>
                        {menuMobileLinkList
                          .filter((_, idx) => idx % 2 === 1)
                          .map((menuMobileLink) => {
                            const { text, openOnANewTab, icon } = menuMobileLink.fields;
                            const inspectorModeLink = contentfulUtils.useInspectorMode(menuMobileLink);

                            return (
                              <Link
                                key={menuMobileLink.sys.id}
                                prefetch={false}
                                href={textUtils.sanitizeContentfulUrl(menuMobileLink, router)}
                                target={openOnANewTab ? "_blank" : undefined}
                              >
                                {icon && isActiveEntry(icon) ? (
                                  <Icon
                                    width={20}
                                    height={20}
                                    svgMedia={icon.fields.svgMedia}
                                    name={icon.fields.svgIconId}
                                  />
                                ) : null}
                                <span {...inspectorModeLink?.getProps("text")}>{text}</span>
                              </Link>
                            );
                          })}
                      </div>
                    </>
                  ) : null}

                  <button className={styles.countryLangButton} onClick={() => openCountryDialog()}>
                    <>
                      <Image
                        unoptimized
                        width={20}
                        height={20}
                        alt={country}
                        src={`/next/assets/flags/${country.toLowerCase()}.svg`}
                      />
                      <span>{router.locale?.replace(/(\w*)\s*-\s*(\w*)/, "$2 | $1").toUpperCase()}</span>
                      <Icon name="plus" />
                    </>
                  </button>
                </>
              )}
            </motion.nav>
          )}
        </AnimatePresence>
      </Dialog>
    </>
  );
}
