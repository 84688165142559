import { useTranslations } from "next-intl";

import { useAuthContext } from "~/contexts/auth";
import { useConfiguration } from "~/contexts/configuration";
import useSubscriptions from "~/hooks/use-subscriptions";
import appRoutes from "~/utils/app-routes";
import loyaltyUtils from "~/utils/loyalty-utils";

import LinkWrapper from "../common/link-wrapper";
import styles from "./modal-logged.module.scss";

type ModalLoggedProps = {};

export default function ModalLogged({}: ModalLoggedProps) {
  const t = useTranslations();
  const { user } = useAuthContext();
  const { configuration } = useConfiguration();
  const { userSubscriptions } = useSubscriptions();

  const tierName = loyaltyUtils.getUserLoyaltyTierName(user, configuration);

  const loyaltySubscription = loyaltyUtils.getLoyaltySubscription(userSubscriptions);

  return (
    <div className={styles.container}>
      <p className={styles.title}>{t("generic.modal_logged.title")}</p>
      <p className={styles.paragraph}>{t("generic.modal_logged.paragraph")}</p>
      {/* Show loyalty points only if user has a balance, otherwise just show the CTA to go to the dahsboard.
      TODO distinguish between user not subscribed to loyalty and country that hasn't a loyalty program.
      https://openmindonline.slack.com/archives/C04MHH5MF18/p1690364304398899 */}
      {typeof loyaltySubscription?.Subscription?.LoyaltyBalance?.Points === "number" ? (
        <>
          <div className={styles.pointsContainer}>
            {/* Hide missing points as per call of the 2023/09/04. Refer to notes at https://openmindonline.slack.com/archives/C04MHH5MF18/p1693900428880229 */}
            {/* <div className={styles.progressBarContainer}>
              <svg className={styles.progressBar} viewBox="0 0 68px 68px" xmlns="http://www.w3.org/2000/svg">
                <circle
                  cx="34px"
                  cy="34px"
                  r="30px"
                  stroke="currentColor"
                  strokeWidth="4px"
                  fill="none"
                  strokeDasharray={`${2 * 30 * Math.PI * 0.7} ${2 * 30 * Math.PI}`}
                  strokeDashoffset={`${(0 / 2) * Math.PI}`}
                />
              </svg>
              <div className={styles.progressBarLabelsContainer}>
                <span className={styles.progressValue}>
                  {loyaltySubscription.Subscription.LoyaltyBalance?.Points}
                </span>
                <span className={styles.progressSeparator}>/</span>
                <span className={styles.progressTotalNextLevel}>XXX</span>
              </div>
            </div> */}
            <div className={styles.pointswrapper}>
              <p className={styles.levelName}>{tierName || t("loyalty.basicLevel")}</p>
              <p className={styles.points}>
                {t("generic.loyaltyPoints", {
                  points: loyaltySubscription.Subscription.LoyaltyBalance?.Points ?? 0,
                })}
              </p>
              {/* Hide missing points as per call of the 2023/09/04. Refer to notes at https://openmindonline.slack.com/archives/C04MHH5MF18/p1693900428880229 */}
              {/* <p className={styles.nextLevel}>
                {t.rich("generic.modal_logged.missing_points", {
                  strong: (chunks) => <strong className={styles.bold}>{chunks}</strong>,
                  points: prefigureDiscountsData?.[0]?.ConditionsResults?.[0]?.LoyaltyPointsMissing,
                })}
              </p> */}
            </div>
          </div>
          <LinkWrapper href={appRoutes.MYACCOUNT} lookAsButton="primary" className={styles.benefitsButton}>
            {t("generic.modal_logged.discover_benefits")}
          </LinkWrapper>
        </>
      ) : (
        <LinkWrapper href={appRoutes.MYACCOUNT} lookAsButton="primary" className={styles.benefitsButton}>
          {t("generic.modal_logged.go_to_dashboard")}
        </LinkWrapper>
      )}
    </div>
  );
}
