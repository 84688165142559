import classNames from "classnames";
import { Entry } from "contentful";
import { useTranslations } from "next-intl";

import Editorials from "~/types/editorials";
import contentfulUtils from "~/utils/contentful-utils";
import { isActiveEntry } from "~/utils/editorial-utils";

import Icon from "../common/icon";
import MediaAsset from "../common/media-asset";
import NavigationLink from "../common/navigation-link";
import styles from "./navigation-collections.module.scss";

type Props = {
  collections: Entry<Editorials.NavigationItemStandardCollections>;
};

export default function NavigationCollections(props: Props) {
  const t = useTranslations();
  if (!isActiveEntry(props.collections)) return null;

  return (
    <div className={styles.collectionsWrapper}>
      {isActiveEntry(props.collections.fields.link) ? <NavigationLink entry={props.collections.fields.link} /> : null}
      {props.collections.fields.list?.filter(isActiveEntry).map((el, i) => {
        if (!isActiveEntry(el.fields.link)) return null;
        const inspectorMode = contentfulUtils.useInspectorMode(el);

        return (
          <NavigationLink key={el.fields.link.sys.id} entry={el.fields.link} className={styles.collectionItem}>
            <div className={styles.imgWrapper} {...inspectorMode?.getProps("image")}>
              <MediaAsset entry={el.fields.image} width={40} height={40} className={styles.preview} />
            </div>
            <span>{el.fields.link.fields.text}</span>
          </NavigationLink>
        );
      })}
      {/* view all link */}
      {isActiveEntry(props.collections.fields.link) ? (
        <NavigationLink entry={props.collections.fields.link} className={styles.collectionItem}>
          <div className={classNames(styles.preview, styles.previewViewAll)}>
            <Icon name="plus" />
          </div>
          <span>{t("generic.view_all")}</span>
        </NavigationLink>
      ) : null}
    </div>
  );
}
