import classNames from "classnames";
import { Entry } from "contentful";
import Link from "next/link";
import { useRouter } from "next/router";
import { useTranslations } from "next-intl";
import { Fragment, useEffect, useState } from "react";
import Modal from "react-modal";

import Editorials from "~/types/editorials";
import contentfulUtils from "~/utils/contentful-utils";
import { assertEditorialType, isActiveEntry } from "~/utils/editorial-utils";
import textUtils from "~/utils/text-utils";

import dialogStyles from "../common/dialog.module.scss";
import Icon from "../common/icon";
import styles from "./navigation.module.scss";
import NavigationCollections from "./navigation-collections";
import NavigationItemCollectionsVariant from "./navigation-item-collections-variant/navigation-item-collections-variant";
import NavigationItemServicesVariant from "./navigation-item-services-variant/navigation-item-services-variant";
import NavigationMenu from "./navigation-menu";
import NavigationServices from "./navigation-services";

type NavigationItemVariantProps = {
  entry: Entry<
    | Editorials.NavigationItemStandard
    | Editorials.NavigationItemCollectionsVariant
    | Editorials.NavigationItemServicesVariant
  >;
};

export function NavigationVariant(props: NavigationItemVariantProps) {
  switch (props.entry.sys.contentType.sys.id) {
    case "navigationItemStandard":
      assertEditorialType<Editorials.NavigationItemStandard>(props.entry, "navigationItemStandard");
      const { menu, collections, services } = props.entry.fields;
      return (
        <>
          {menu && isActiveEntry(menu) ? <NavigationMenu menu={menu} /> : null}
          {collections && isActiveEntry(collections) ? <NavigationCollections collections={collections} /> : null}
          {services && isActiveEntry(services) ? <NavigationServices services={services} /> : null}
        </>
      );
    case "navigationItemCollectionsVariant":
      assertEditorialType<Editorials.NavigationItemCollectionsVariant>(props.entry, "navigationItemCollectionsVariant");
      return <NavigationItemCollectionsVariant entry={props.entry.fields.list?.filter(isActiveEntry)} />;

    case "navigationItemServicesVariant":
      assertEditorialType<Editorials.NavigationItemServicesVariant>(props.entry, "navigationItemServicesVariant");
      return (
        <>
          <NavigationItemServicesVariant entry={props.entry.fields.list?.filter(isActiveEntry)} />
          {props.entry.fields.collections && isActiveEntry(props.entry.fields.collections) ? (
            <NavigationCollections collections={props.entry.fields.collections} />
          ) : null}
        </>
      );

    default: {
      return null;
    }
  }
}

type Props = {
  nav: Entry<Editorials.Nav>;
  className?: string;
};

export default function Navigation(props: Props) {
  const t = useTranslations();
  const router = useRouter();

  const [currentNavOpen, setCurrentNavOpen] = useState<number>();

  // close menu/search on change route
  useEffect(() => {
    setCurrentNavOpen(undefined);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router.asPath]);

  function toggleNavItem(index: number) {
    if (currentNavOpen == index) {
      setCurrentNavOpen(undefined);
    } else {
      setCurrentNavOpen(index);
    }
  }

  return (
    <nav className={classNames(styles.nav, props.className)}>
      {props.nav.fields.navigationItems?.filter(isActiveEntry).map((el, i) => {
        const isNavigationStandard = el.sys.contentType.sys.id === "navigationItemStandard";
        const inspectorModeLink = contentfulUtils.useInspectorMode(el.fields.link);

        //if navigationItemStandard has no menus, collections and services, then it becomes a top-level link
        if (isNavigationStandard) {
          assertEditorialType<Editorials.NavigationItemStandard>(el, "navigationItemStandard");
          const { link, menu, collections, services } = el.fields;

          if (!isActiveEntry(menu!) && !isActiveEntry(collections!) && !isActiveEntry(services!) && isActiveEntry(link))
            return (
              <Link
                key={el.sys.id}
                href={textUtils.sanitizeContentfulUrl(link, router)}
                target={link.fields.openOnANewTab ? "_blank" : undefined}
                className={classNames(styles.navItem, { [styles.navItemActive]: currentNavOpen == i })}
                onClick={() => toggleNavItem(i)}
                {...inspectorModeLink?.getProps("text")}
              >
                {link.fields.text}
              </Link>
            );
        }

        return (
          <Fragment key={el.sys.id}>
            {isActiveEntry(el.fields.link) ? (
              <button
                aria-haspopup="dialog"
                onClick={() => toggleNavItem(i)}
                className={classNames(styles.navItem, { [styles.navItemActive]: currentNavOpen == i })}
                {...inspectorModeLink?.getProps("text")}
              >
                {el.fields.link.fields.text}
              </button>
            ) : null}
            <Modal
              appElement={
                typeof window !== "undefined" ? (document.getElementById("__next") as HTMLElement) : undefined
              }
              isOpen={currentNavOpen == i}
              onRequestClose={() => setCurrentNavOpen(undefined)}
              onAfterClose={() => {
                document.body.classList.remove("ReactModal__Body--open");
              }}
              overlayClassName={styles.dialogOverlay}
              className={styles.dialogWrapper}
              closeTimeoutMS={600}
            >
              <div className={classNames(dialogStyles.panel, styles.navPanel)}>
                <div className={classNames(dialogStyles.top, styles.dialogTop)}>
                  <div className={classNames(dialogStyles.header, styles.dialogHeader)}>
                    <div className={classNames(dialogStyles.title, styles.dialogTitle)}>
                      {isActiveEntry(el.fields.link) ? (
                        <Link
                          href={textUtils.sanitizeContentfulUrl(el.fields.link, router)}
                          target={el.fields.link.fields.openOnANewTab ? "_blank" : undefined}
                          {...inspectorModeLink?.getProps("text")}
                        >
                          {isNavigationStandard
                            ? t("generic.view_all_with", { name: el.fields.link.fields.text })
                            : t("generic.view_all_with_variant2", { name: el.fields.link.fields.text })}
                        </Link>
                      ) : null}
                    </div>
                  </div>
                  <button className={dialogStyles.close} onClick={() => setCurrentNavOpen(undefined)}>
                    <Icon name="close" />
                  </button>
                </div>
                <div className={classNames(dialogStyles.content, styles.dialogContent)}>
                  <NavigationVariant entry={el} />
                </div>
              </div>
            </Modal>
          </Fragment>
        );
      })}
    </nav>
  );
}
