import classNames from "classnames";
import { Entry } from "contentful";
import Link from "next/link";
import { useRouter } from "next/router";
import { useTranslations } from "next-intl";
import { useState } from "react";
import { useSetRecoilState } from "recoil";

import { mobileMenuOpenedState } from "~/atoms/mobileMenu";
import Editorials from "~/types/editorials";
import contentfulUtils from "~/utils/contentful-utils";
import { assertEditorialType, isActiveEntry } from "~/utils/editorial-utils";
import textUtils from "~/utils/text-utils";

import HighlightedText from "../common/highlighted-text";
import Icon from "../common/icon";
import MediaAsset from "../common/media-asset";
import NavigationLink from "../common/navigation-link";
import styles from "./menu-mobile-item.module.scss";
import NavigationItemCollectionsVariantItem from "./navigation-item-collections-variant/navigation-item-collections-variant-item";
import NavigationItemServicesVariantItem from "./navigation-item-services-variant/navigation-item-services-variant-item";

type ItemOptionsWithVariableState = Entry<
  | Editorials.NavigationItemStandard
  | Editorials.NavigationItemCollectionsVariant
  | Editorials.NavigationItemServicesVariant
  | Editorials.NavigationItemStandardCollections
  | Editorials.MenuGroup
>;

type SetImagesCollectionProps = {
  item: ItemOptionsWithVariableState;
};

export function SetImagesCollection({ item }: SetImagesCollectionProps) {
  assertEditorialType<Editorials.NavigationItemStandardCollections>(item, "navigationItemStandardCollections");
  const inspectorModeLink = contentfulUtils.useInspectorMode(item.fields.link);

  return (
    <div className={styles.collectionsWrapper}>
      <div className={styles.imageCollectionsWrapper}>
        <div className={styles.imageBox}>
          {item.fields.list
            ?.filter(isActiveEntry)
            .slice(0, 3)
            .map((img, i) => {
              const inspectorModeLink = contentfulUtils.useInspectorMode(img);
              return (
                <>
                  <div className={styles.wrapperMedia} {...inspectorModeLink?.getProps("image")}>
                    <MediaAsset key={i} entry={img.fields.image} width={40} height={40} />
                  </div>
                </>
              );
            })}
        </div>
        {isActiveEntry(item.fields.link) ? (
          <span {...inspectorModeLink?.getProps("text")}>{item.fields.link.fields.text}</span>
        ) : null}
      </div>
      <Icon name="chevron-right" />
    </div>
  );
}

type GoAheadProps = {
  item: ItemOptionsWithVariableState;
  setCurrent: (updated: ItemOptionsWithVariableState) => void;
  className?: string;
  isCollection?: boolean;
};

export function GoAheadButton({ isCollection = false, ...props }: GoAheadProps) {
  const t = useTranslations();
  const [ariaExpanded, setAriaExpanded] = useState(false);
  if (!isActiveEntry(props.item.fields.link)) return null;

  const inspectorModeLink = contentfulUtils.useInspectorMode(props.item.fields.link);

  return (
    <button
      onClick={() => {
        setAriaExpanded(!ariaExpanded);
        props.setCurrent(props.item);
      }}
      aria-label={`${props.item.fields.link.fields.text} ${t("generic.ahead")}`}
      aria-expanded={ariaExpanded}
      className={classNames(styles.navItem, props.className)}
    >
      {isCollection ? (
        <SetImagesCollection item={props.item} />
      ) : (
        <>
          <span {...inspectorModeLink?.getProps("text")}>{props.item.fields.link.fields.text}</span>
          <Icon name="chevron-right" />
        </>
      )}
    </button>
  );
}

type ItemOptions = Entry<
  | Editorials.NavigationItemStandard
  | Editorials.NavigationItemCollectionsVariant
  | Editorials.NavigationItemServicesVariant
  | Editorials.NavigationItemServicesVariantCategory
  | Editorials.NavigationItemStandardCollectionsItem
  | Editorials.NavigationItemStandardCollections
  | Editorials.MenuGroup
  | Editorials.Link
>;

type Props = {
  item: ItemOptions;
  setCurrent: (updated: ItemOptionsWithVariableState) => void;
  currentLevel?: number;
  className?: string;
};

export default function MenuMobileItem(props: Props) {
  const router = useRouter();
  const setMobileMenuOpenedState = useSetRecoilState<boolean>(mobileMenuOpenedState);
  if (!isActiveEntry(props.item)) return null;

  switch (props.item.sys.contentType.sys.id) {
    case "navigationItemStandard":
      assertEditorialType<Editorials.NavigationItemStandard>(props.item, "navigationItemStandard");
      const { link, menu, collections, services } = props.item.fields;
      const inspectorModeLink = contentfulUtils.useInspectorMode(link);

      //if navigationItemStandard has no menus, collections and services, then it becomes a top-level link
      if (!isActiveEntry(menu!) && !isActiveEntry(collections!) && !isActiveEntry(services!) && isActiveEntry(link))
        return (
          <Link
            href={textUtils.sanitizeContentfulUrl(link, router)}
            target={link.fields.openOnANewTab ? "_blank" : undefined}
            className={classNames(styles.navItem, props.className)}
            onClick={() => setMobileMenuOpenedState(false)}
          >
            <span {...inspectorModeLink?.getProps("text")}>{link.fields.text}</span>
          </Link>
        );

      return <GoAheadButton item={props.item} setCurrent={props.setCurrent} />;

    case "navigationItemStandardCollections":
      assertEditorialType<Editorials.NavigationItemStandardCollections>(
        props.item,
        "navigationItemStandardCollections"
      );
      return <GoAheadButton item={props.item} setCurrent={props.setCurrent} isCollection />;

    case "navigationItemCollectionsVariant":
      assertEditorialType<Editorials.NavigationItemCollectionsVariant>(props.item, "navigationItemCollectionsVariant");
      return <GoAheadButton item={props.item} setCurrent={props.setCurrent} />;

    case "navigationItemServicesVariant":
      assertEditorialType<Editorials.NavigationItemServicesVariant>(props.item, "navigationItemServicesVariant");
      return <GoAheadButton item={props.item} setCurrent={props.setCurrent} />;

    case "navigationItemServicesVariantCategory":
      assertEditorialType<Editorials.NavigationItemServicesVariantCategory>(
        props.item,
        "navigationItemServicesVariantCategory"
      );
      const inspectorMode = contentfulUtils.useInspectorMode(props.item);

      return (
        <div className={styles.serviceCategoryContainer}>
          {props.item.fields.title ? (
            <div
              className={
                contentfulUtils.isHighlightText(props.item.fields.title)
                  ? styles.serviceCategoryTitleHighlighted
                  : styles.serviceCategoryTitle
              }
              {...inspectorMode?.getProps("title")}
            >
              <HighlightedText text={props.item.fields.title} />
            </div>
          ) : null}

          {props.item.fields.list?.filter(isActiveEntry).map((serviceItem, index) => {
            return <NavigationItemServicesVariantItem key={index} entry={serviceItem} isMobile />;
          })}
        </div>
      );
    case "menuGroup":
      assertEditorialType<Editorials.MenuGroup>(props.item, "menuGroup");
      //items higher than second category aren't shown
      if (props.item.fields.items?.filter(isActiveEntry) && props.currentLevel !== 2) {
        return <GoAheadButton item={props.item} setCurrent={props.setCurrent} />;
      } else {
        const inspectorModeLink = contentfulUtils.useInspectorMode(props.item.fields.link);

        return (
          <>
            {isActiveEntry(props.item.fields.link) ? (
              <Link
                href={textUtils.sanitizeContentfulUrl(props.item.fields.link, router)}
                className={classNames(styles.navItem, props.className)}
                onClick={() => setMobileMenuOpenedState(false)}
              >
                <span {...inspectorModeLink?.getProps("text")}>{props.item.fields.link.fields.text}</span>
              </Link>
            ) : null}
          </>
        );
      }

    case "navigationItemStandardCollectionsItem":
      assertEditorialType<Editorials.NavigationItemStandardCollectionsItem>(
        props.item,
        "navigationItemStandardCollectionsItem"
      );
      return <NavigationItemCollectionsVariantItem entry={props.item} />;

    case "link":
      assertEditorialType<Editorials.Link>(props.item, "link");
      return (
        <>
          {isActiveEntry(props.item) ? (
            <NavigationLink
              entry={props.item}
              className={classNames(styles.navItem, props.className)}
              onClick={() => setMobileMenuOpenedState(false)}
            />
          ) : null}
        </>
      );
    default: {
      return null;
    }
  }
}
