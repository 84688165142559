import { useQuery } from "@tanstack/react-query";
import classNames from "classnames";
import { motion } from "framer-motion";
import Image from "next/image";
import Link from "next/link";
import { useTranslations } from "next-intl";
import { Dispatch, SetStateAction } from "react";

import stylesButton from "~/components/common/button.module.scss";
import Icon from "~/components/common/icon";
import ProductLabel from "~/components/products/product-label";
import ProductPrice from "~/components/products/product-price";
import ProductRatings from "~/components/products/product-ratings";
import { useBreakpoint } from "~/contexts/breakpoint";
import { useConfiguration } from "~/contexts/configuration";
import CrossCountry from "~/types/crossCountry";
import { basicAnimationStates } from "~/utils/animation-utils";
import appRoutes from "~/utils/app-routes";
import evaUtils from "~/utils/eva-utils";
import imageUtils from "~/utils/image-utils";

import styles from "./eva-search-dialog.module.scss";

type Props = {
  query: string;
  setQuery: Dispatch<SetStateAction<string>>;
  searchSuggestions?: string[];
};

export default function EvaSearchDialog(props: Props) {
  const { eva } = useConfiguration();

  const t = useTranslations();
  const breakpoint = useBreakpoint();

  const query = useQuery({
    queryKey: ["quick-search", props.query, breakpoint],
    keepPreviousData: true,
    staleTime: Infinity,
    queryFn: () => {
      if (props.query != "") {
        return evaUtils.getProductsByQueryWithChildren(
          eva,
          props.query,
          undefined,
          undefined,
          breakpoint == "desktop" ? 8 : 4
        );
      }
      return null;
    },
  });

  return (
    <motion.div
      key={"search"}
      variants={basicAnimationStates}
      initial="off"
      animate="on"
      exit="off"
      className={styles.searchWrapper}
    >
      {props.query != "" && query.data?.Products && query.data.Products.length > 0 ? (
        <>
          <div className={styles.searchBox}>
            <h2 className={styles.searchHeading}>{t("generic.products")}</h2>
            <div className={styles.searchList}>
              {query.data?.Products?.map((product: CrossCountry.Product) => {
                return (
                  <Link
                    key={product.product_id}
                    href={`/p/${
                      product.slug + "-" + (product.children ? product.children[0].product_id : product.product_id)
                    }`}
                    className={styles.searchProduct}
                  >
                    <Image
                      priority
                      src={
                        (product.children ? product.children[0] : product).product_media?.primary_image?.url ||
                        imageUtils.transparentPixel
                      }
                      alt={product.display_value}
                      width={80}
                      height={80}
                      className={styles.searchImg}
                    />
                    <div className={styles.searchProductCol}>
                      {product.discounts?.length ? (
                        <ProductLabel>{product.discounts[0].PromotionLabel}</ProductLabel>
                      ) : null}
                      {product.display_value}
                      <div className={styles.searchProductRow}>
                        <ProductPrice
                          product={product}
                          customStyles={{
                            container: styles.containerPrice,
                            discount: styles.discount,
                            displayPrice: styles.displayPrice,
                            originalPrice: styles.originalPrice,
                          }}
                        />
                        <div className={styles.ratings}>
                          <ProductRatings
                            average={product.review_average_score || 0}
                            numberOfRatings={product.review_total_number}
                          />
                        </div>
                      </div>
                      {product.children && (
                        <span className={styles.searchShadesAvailables}>
                          {t("generic.shadesavailables", { 0: product.children.length })}
                          <Icon name="chevron-right" width={16} height={16} />
                        </span>
                      )}
                    </div>
                  </Link>
                );
              })}
            </div>
            <Link
              href={`/search?query=${encodeURIComponent(props.query)}`}
              className={classNames(stylesButton.button, stylesButton.primary, styles.searchButton)}
            >
              {t("generic.viewAllProducts")}
            </Link>
          </div>
          {/* TODO implement this with algolia */}
          {/* <div className={styles.searchBox}>
            <h2 className={styles.searchHeading}>Tutorials</h2>
            <Button variant="primary" className={styles.searchButton}>
              {t("generic.viewAllTutorials")}
            </Button>
          </div> */}
          <div className={classNames(styles.searchBox, styles.searchAlert)}>
            <p>
              {t.rich("generic.askToKikoHelpCenter", {
                link: (chunks) => {
                  return (
                    <Link href={appRoutes.CUSTOMER_HELP_CENTER} className={styles.link}>
                      {chunks?.toString()}
                    </Link>
                  );
                },
              })}
            </p>
          </div>
        </>
      ) : (
        <div className={classNames(styles.searchBox, styles.searchSuggestions)}>
          <h2 className={styles.searchHeading}>{t("generic.suggestions")}</h2>
          <div className={styles.searchSuggestionList}>
            {props.searchSuggestions?.map((suggestion, index) => (
              <button key={index} className={styles.searchSuggestionItem} onClick={() => props.setQuery(suggestion)}>
                {suggestion}
              </button>
            ))}
          </div>
        </div>
      )}
    </motion.div>
  );
}
